import { Controller } from "@hotwired/stimulus"
import _debug from "debug"

const debug = _debug("video_controller")

export default class extends Controller {
    static targets = ["player", "cover", "source"];
    static values = { playFullscreen: { type: Boolean, default: false } };

    onFullscreenChange = () => {
        this.coverTarget.classList.remove("hidden")
        this.playerTarget.pause()
        this.hideControls()

        document.removeEventListener("fullscreenchange", this.onFullscreenChange, false);
        document.removeEventListener("mozfullscreenchange", this.onFullscreenChange, false);
        document.removeEventListener("webkitfullscreenchange", this.onFullscreenChange, false);
    }

    connect() {
        this.hideControls();

        this.boundShowControls = this.showControls.bind(this);
        this.playerTarget.addEventListener("playing", this.boundShowControls);
    }

    hideControls() {
        debug("hiding controls")
        this.playerTarget.controls = false;
    };

    showControls() {
        debug("showing controls")
        this.playerTarget.controls = true;
    };

    playPause() {
        if (this.playerTarget.paused) {
            debug("playing video")
            if (this.hasCoverTarget) {
                this.coverTarget.classList.add("hidden")
            }
            if (this.playFullscreenValue) {
                debug("requesting fullscreen")
                this.requestFullscreen()
            }
            this.playerTarget.play()
        } else {
            this.playerTarget.pause()
        }
    }

    switchSource(event) {
        this.playerTarget.setAttribute("poster", event.params.poster)
        this.sourceTargets.forEach((src) => src.remove())
        const source = document.createElement("source")
        source.setAttribute("src", event.params.source)
        source.setAttribute("type", event.params.sourceType)
        source.setAttribute("data-video-target", "source")
        this.playerTarget.appendChild(source)
        this.playerTarget.load()
    }

    requestFullscreen() {
        if (this.playerTarget.requestFullscreen) {
            this.playerTarget.requestFullscreen()
        } else if (this.playerTarget.mozRequestFullScreen) {
            this.playerTarget.mozRequestFullScreen()
        } else if (this.playerTarget.webkitRequestFullscreen) {
            this.playerTarget.webkitRequestFullscreen()
        } else if (this.playerTarget.msRequestFullscreen) {
            this.playerTarget.msRequestFullscreen()
        }

        document.addEventListener("fullscreenchange", this.onFullscreenChange, false);
        document.addEventListener("mozfullscreenchange", this.onFullscreenChange, false);
        document.addEventListener("webkitfullscreenchange", this.onFullscreenChange, false);
    }
}
