import { Controller } from "@hotwired/stimulus"
import { patch } from "@rails/request.js"
import debounce from "lodash/debounce"
import _debug from "debug"

const debug = _debug("survey-form")

function buildIncrementalFormData(formElement, scope) {
  const originalForm = new FormData(formElement)
  const body = new FormData()
  Array.from(originalForm.entries()).forEach(([name, value]) => {
    if (name.startsWith(scope)) {
      body.append(name, value)
    }
  })
  body.append("incremental", "true")
  return body
}

async function incrementalUpdate(url, body, updateId) {
  debug("INCR", updateId, "Starting...")
  try {
    const response = await patch(url, {body})
    if (!response.ok) {
      debug("INCR", updateId, "Failure status code received while saving input:", response)
    } else {
      debug("INCR", updateId, "Saved input")
    }
  } catch (error) {
    debug("INCR", updateId, "Error saving input:", error)
  }
}

// Connects to data-controller="survey-form"
export default class extends Controller {
  static targets = ["form"]

  updateCounter = 0
  updates = []
  updating = false

  initialize() {
    super.initialize();
    this.saveInputDebounced = debounce(this.saveInput, 500)
  }

  connect() {
  }

  async saveInput(event) {
    const body = buildIncrementalFormData(this.formTarget, event.params.scope)
    await this.enqueueUpdate(body)
  }

  async saveVideoInput(event) {
    const body = buildIncrementalFormData(this.formTarget, event.params.scope)
    const newBody = new FormData()
    Array.from(body.entries()).forEach(([name, value]) => {
      if (value !== "" && !(value instanceof File)) {
        newBody.append(name, value)
      }
    })
    await this.enqueueUpdate(body)
  }

  async enqueueUpdate(body) {
    if (this.updating) {
      this.updates.push(body)
    } else {
      this.updating = true
      const updateId = ++this.updateCounter
      this.formTarget.setAttribute("data-incr-update", "updating")
      try {
        await incrementalUpdate(this.formTarget.getAttribute("action"), body, updateId)
        while (this.updates.length > 0) {
          const updateId = ++this.updateCounter
          await incrementalUpdate(this.formTarget.getAttribute("action"), this.updates.shift(), updateId)
        }
      } finally {
        this.updating = false
        this.formTarget.setAttribute("data-incr-update", "completed")
      }
    }
  }
}
